.artwork {
	margin-top: 40px;

	ul {
		margin: 0;
		padding: 0;
	
		li {
			float: left;
			position: relative;
			width: 175px;
    		height: 175px;
    		margin-right: 3px;
    		margin-bottom: 3px;
		}
	}

	a {
		position: relative;
		width: 100%;
		height: 100%;
		display: block;
		z-index: 15;
	}

	.release-info {
			z-index: 10;
			top: 0;
			left: 0;
			position: absolute;
			width: 100%;
			height: 100%;
			background-color: $red;
			opacity: 0;
			@include transition(all 0.4s ease-in-out);	
			
			span {
				color: $white;
				text-transform: uppercase;
				font-family: $raleway;
			}

			.year {
				position: absolute;
				top: 15px;
				right: 10px;
				font-size: 44px;
				font-weight: 400;

			}

			.release-details {
				position: absolute;
				bottom: 10px;
				left: 10px;
				span {
					display: block;
					font-weight: 600;
					font-size: 85%;
					line-height: 1.4em;
					width: 100%;
					padding-right: 10px;
				}

				.release {
					font-weight: 300;
				}

				.task {
					margin-top: 5px;
					color: $black;
					font-weight: 700;
				}
			}
	}
	
	// a:hover + .release-info,
	.artwork-active {
		z-index: 16;
		@include transition(all 0.2s ease-in-out);	
		opacity: 0.9;
		-webkit-touch-callout: none; /*only to disable context menu on long press*/
	}


	img {
		width: 175px;
		height: 175px;
	}


}


// surrealcms 
.is-cms .artwork {
	ul li {
		width: auto;
		height: auto;

		.artwork-item {
			img {
				position: relative;
			} 
		}
		.release-info {
			position: relative;
			background-color: $white;
			top: inherit;
			display: block;
			opacity: 1;
			width: 175px;
			height: 150px;
			* {
				display: block;
				font-size: inherit;
				padding: 5px 0;
				position: relative;
				top: inherit;
				left: inherit;
				color: $red;
			}
		}
	}
}