/// Mobile Menu ///



.icon-menu {
    position: absolute;
    bottom: 10px;
    left: 20px;
    color: white;
    width: 35px;
    height: 35px;
    display: block;
    top: 17px;
    display: none;
    @include media($tablet-large) {
        display: block;
        left: 20px;
    }
}

.brand-mobile {
    position: absolute;
    bottom: 10px;
    left: 50px;
    color: white;
    font-family: $raleway;
    font-size: 12px;
    text-transform: uppercase;
    display: block;
    top: 12px;
    display: none;
    @include media($tablet-large) {
        display: block;
        left: 50px;
    }
}
