// Web Font Loader. Prevent Flash Of Unstyled Content (FOUC)

.wf-loading * {
    opacity: 0;
}

.wf-loading:after {

    content: "loading fonts..";

    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;

    color: #135040;
    font-size: 1.5em;
    font-weight: bold;
    line-height: 20em;
    text-align: center;

}

.wf-active *,
.wf-inactive * {
    -webkit-transition: opacity 1s ease-out;
    -moz-transition: opacity 1s ease-out;
    -o-transition: opacity 1s ease-out;
    transition: opacity 1s ease-out;
}