// Footer

footer { 
	z-index: 10;
	display: block;
	position: fixed;
	bottom: 0;
	width: 100%;
	background: $red;  
	padding: 0;
	margin: 0;
	height: 43px;
	@include transition(all 0.2s ease);

	// @include media($desktop-small) {
	// 	height: 35px;
	// }

}
