// refill bourbon
// sliding panel

.sliding-panel-content {
  $action-color: $red !default;
  $dark-gray: $red !default;
  $sliding-panel-border-color: $dark-gray;
  $sliding-panel-background: lighten($sliding-panel-border-color, 5%);
  $sliding-panel-color: #fff;
  $sliding-panel-border: 1px solid lighten($sliding-panel-border-color, 5%);
  $sliding-panel-background-hover: $action-color;
  $sliding-panel-color-hover: #fff;
  $sliding-panel-background-focus: lighten($sliding-panel-background, 5%);

  @include position(fixed, 0 auto 0 0);
  @include size(100% 100%);
  background-color: $dark-gray;
  background-image: url(../images/content/bg-mobile.jpg);
  background-size: cover;
  background-repeat: no-repeat;

  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  transform: translateY(100%);
  transition: all 0.25s linear;
  z-index: 999999;

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    list-style: none;
  }

  li a {
    border-bottom: $sliding-panel-border;
    color: $sliding-panel-color;
    display: block;
    font-weight: 400;
    text-transform: uppercase;
    padding: 1em;
    text-decoration: none;

    &:focus {
      background-color: $sliding-panel-background-focus;
    }

    &:hover {
      background-color: $sliding-panel-background-hover;
      color: $sliding-panel-color-hover;
    }
  }

  &.is-visible {
    transform: translateX(0);
  }
}

.sliding-panel-fade-screen {
  @include position(fixed, 0);
  background: black;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  visibility: hidden;
  z-index: 999998;

  &.is-visible {
    opacity: 0.5;
    visibility: visible;
  }
}

.sliding-panel-button {
  cursor: pointer;
  display: inline-block;
  outline: none;
  padding: 10px 20px;
  position: relative;

  img {
    height: 1.4em;
  }
}

.icon-arrow-down {
  position: absolute;
  bottom: -5px;
  left: 20px;
  color: $white;
  width: 32px;
  height: 32px;
  display: block;
  // font-size: 14pt;
  span {
    display: inline;

  }
}

// Based on code by Diego Eis
