// Font size helpers

.mega {
	font-size: $font-mega-size;
	line-height: 1;
}


.h1 { font-size: $font-size-h1; }
.h2 { font-size: $font-size-h2; }
.h3 { font-size: $font-size-h3; }
.h4 { font-size: $font-size-h4; }
.h5 { font-size: $font-size-h5; }
.h6 { font-size: $font-size-h6; }


// Font weight helpers

.text-std {
	font-family: $font-size-base;
}

.text-subheader {
	font-family: $font-size-h2;
}

.text-header, .nav-link {
	font-family: $font-size-base;
}

.brand {
	color: $primary;
}

/// Fonts ///

.font-base {
    font-size: $font-size-base;
}

.font-small {
    font-size: $font-size-small;
}

.font-smaller {
    font-size: $font-size-smaller;
}

.font-large {
    font-size: $font-size-large;
}

.font-larger {
    font-size: $font-size-larger;
}