.section {
    position: relative;
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000; 
    //@include pad(0px 50px);
    
    .section-content {
        @include outer-container($max-width);   
    
        margin: 0 50px 50px; 
        width: auto;

        @include media($tablet) {
        	margin: 0 20px 50px;
        }

        a.text-link {
            color: $red;
            border-bottom: 1px solid $red;
            text-decoration: none;
        }
    }

    .section-content-wide {
        @include pad(0px);
    }

    .block-content {
        
        .focus {
            color: $red;
            font-weight: 600;
        }
    }

    .section-block {
        padding-bottom: 50px;
    }

   
}