
@font-face {
    font-family: 'Malgun';
    src: url("../fonts/malgun.eot?#iefix");
    src: url("../fonts/malgun.eot?#iefix") format("eot"),
        url("../fonts/malgun.woff2") format("woff2"),
        url("../fonts/malgun.woff") format("woff"),
        url("../fonts/malgun.ttf") format("truetype"),
        url('../fonts/malgun.svg#malgun') format('svg');
    font-weight: normal;
    font-style: normal;
}

// font-family: 'Playfair Display', serif;
// font-family: 'Raleway', sans-serif;