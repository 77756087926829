/// Layout ///

html {
    height: 100%;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // -webkit-user-select: none; Chrome all / Safari all
    // -o-user-select: none;
    // user-select: none;
    -webkit-touch-callout:none;

    line-height: 1.4em;
    background: $white;
    padding: 0;
    margin: 0;
    height: 100%;
    font-family: $raleway;
    font-size: 85%;
    @include media($desktop-small) {
        padding-top: 0;
    }

    &.mobile-nav-active {
        overflow-y: hidden;
    }
}

ul {
    list-style: none;
}

h1, h2 {
    color: $red;
    font-family: $malgun;
    font-size: 3vw;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    padding: 0;
    margin: 0;
}

h1 {
    padding-top: 50px;

    @include media($tablet) {
        font-size: 24px;
        padding-top: 30px;
    }

    
}

header .eq {
    display: block;
    position: absolute;
    left: 0;
    top: 30px;
    background: url(../images/content/eq.png) no-repeat;
    width: 86px;
    height: 79px;
    background-size: 50%;
    opacity: 0.3;
    .section-home & {
        display: none;
    }
    @include media($tablet) {
        display: none;
    }
}

h2 {
    font-size: 1.7vw;
    margin: 60px 0 15px;
    margin: 4vw 0 1.5vw;
    @include media($tablet) {
        font-size: 16px;
        margin: 30px 0 0px;
    }
}

h3 {
    font-weight: 700;
    margin-bottom: 20px 0;
    font-size: inherit;
    text-decoration: none;
    letter-spacing: 0.05em;
    @include media($tablet) {
        margin: 30px 0px;
    }
}

h4, p {
    font-family: $raleway;
    margin: 20px 0;
    line-height: 1.6em;
    letter-spacing: 0.03em;
    font-weight: 300;
}

h4 {
    margin: 15px 0;
}