/// Font families ///

$malgun: "Malgun", Arial, sans-serif;
$playfair: "Playfair Display", 'Times New Roman', serif;
$raleway: "Open Sans", sans-serif, arial; 

// font-family: 'Playfair Display', serif;
// font-family: 'Raleway', sans-serif;

$font-base: $raleway;
$font-head: $malgun;
$font-subhead: $playfair;

$headings-font-weight: normal;


/// Font sizes

// Body

$line-height-base: 24/16; 
$line-height-std: 1.35em; 

$font-size-xlarge: 32px;
$font-size-larger: 24px;
$font-size-large: 18px;
$font-size-base: 16px;
$font-size-small: 14px;
$font-size-smaller: 13px;

// Headings
$font-size-h1: 64px;
$font-size-h2: 24px;
$font-size-h3: 24px;
$font-size-h4: 18px;
$font-size-h5: 16px;
$font-size-h6: 14px;

$font-mega-size: 110/16 + em;