// Primary Nav

nav.primary-nav {
	ul {
		margin: 0;
		padding: 0;
		li {
			float: left;
			display: table;  
			margin: 0;
			padding: 0;
			text-align: center;
 			
			@include media($tablet-large) {
				display: none;
			}

			a {
				position: relative; 
 				height: 43px;
 				font-family: $malgun;
 				//display: block; 
				display: table-cell;
				vertical-align: middle;
				width: 100%;
				text-decoration: none;
				color: $offwhite;
				letter-spacing: 0.05em;
				font-size: 14px;
				//font-size: 1vmax;				 
				text-transform: uppercase;
				padding: 0 1.2vw;
				white-space: nowrap;

				white-space: nowrap; 		/* css-3 */
				white-space: -moz-nowrap; /* Mozilla, since 1999 */
				white-space: -nowrap; 	/* Opera 4-6 */
				white-space: -o-nowrap; 	/* Opera 7 */ 
				
				border-bottom: 2px solid $red;

				@include media($desktop-small) {
					font-size: 11px;
					padding: 0 1vw;
				}
			}

			.nav-active {
				border-bottom: 2px solid $offwhite;
			}

			.nav-home {
				padding-left: 50px;
				color: $pink;
				&.nav-active {
					border-bottom: 2px solid $red;		
				}
			}

		}
	}
}

nav#social {
	ul {
		float: right;
		margin: 0;
		padding: 0;
		margin-top: 13px;
		margin-right: 10px;


		li {
			float: left;
			a {
				color: $white;
				display: inline-block;
				width: 35px;
				height: 35px;
				font-size: 12pt;
				text-decoration: none;				 
			}
		}
	}
}

nav a {
	@include transition(all 0.2s ease);
	&:hover {
		color: $pink !important;
	}
}	

