.section-home {
	background: center right url(../images/content/bg-home.jpg) no-repeat;
	background-size: cover; 
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;

	.section-content {
		padding-bottom: 0px;	
		@include media($mobile) {
			margin-bottom: 0;
		}
	}
	


	.redblock {
		position: absolute;
		left: 0;
		top: 42%;
		display: block;
		height: 115px;
		width: 15px;
		background: $red;
		@include media($mobile) {
			display: none;
		}
	}

	h1 {
		position: absolute;
		top: 42%;
		left: 7vw;
		@include media($mobile) {
			top: inherit;
			left: inherit;
			position: relative;
		}
	}

	p {
		font-weight: 600;
		position: absolute;
		top: 59%;
		top: calc(42% + 80px);	
		left: 7vw;
		color: $red;
		@include media($mobile) {
			top: inherit;
			left: inherit;
			position: relative;
		}
	}

	.block-content {
		@include span-columns(6);
		@include media($tablet) {
			@include span-columns(7); 
		}
		@include media($mobile) {
    		@include span-columns(12); 
    	}
	}

	.section-art {
		position: absolute;
		top: 0;
		@include media($desktop-small) {
			top: 6vw;
			position: relative;
		}
		@include media($tablet) {
			position: absolute;
			top: 34%;
			left: 55%;
		}
		@include media($mobile) {
			position: relative;
			top: 240px;
			left: 40px;
		}


		.artwork-item {
			display: block;
			position: absolute;
			width: 175px;
			height: 175px;
			display: block;
			@include media($desktop-large) {
				width: 225px;
				height: 225px;
			}
			@include media($desktop-small) {
				width: 125px;
				height: 125px;
			}
			@include media($tablet) {
		    	top: 0px !important;
		    	left: 0px !important;
		    	width: 225px;
				height: 225px;
				z-index: 20 !important;
		    }
		}

		.artwork-1 {
			top: 3.5vw;
			left: 62vw;
			z-index: 20;
			
		}

		.artwork-2 {
			top: 5vw;
			left: 74vw;
			z-index: 19;
			
		}

		.artwork-3 {
			top: 24vw;
			left: 74vw;
			z-index: 24;
		}

		.artwork-4 {
			top: 19vw;
			left: 82vw;
			z-index: 23;
		}

		.artwork-5 {
			top: 10vw;
			left: 57vw;
			z-index: 21;
			
		}

		.artwork-6 {
			top: 31vw;
			left: 71vw;
			z-index: 28;
			width: 127px;
			@include media($desktop-large) {
				width: 163px;
				height: 225px;
			}
			@include media($desktop-small) {
				width: 91px;
				height: 125px;
			}
			@include media($tablet) {
				width: 164px;
				height: 225px;
			}
		}

		.artwork-7 {
			top: 13vw;
			left: 78vw;
			z-index: 20;
		}

		.artwork-8 {
			top: 21vw;
			left: 59vw;
			z-index: 23;
		}

		.artwork-9 {
			top: 16vw;
			left: 66vw;
			z-index: 22;
		}

		.artwork-10 {
			top: 27vw;
			left: 62vw;
			z-index: 26;
		}
	}

	.mobile-only {
		display: none;
		@include media($mobile) {
			display: block;
			margin-top: 0px;	
			float: left;

			ul {
				border-left: 5px solid $red;
				padding-left: 20px;
				li {
					margin: 12px;
					&:first-child {
						display: none;
					}
					a {
						color: $red;
						text-decoration: none;
						font-weight: 600;
						text-transform: uppercase;
						font-size: 120%;
					}
				}

			}
		}
	}
}

.section-about {
	background: right 400px url(../images/content/bg-about2.png) no-repeat;
	background-size: 90%;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	// @include media($tablet) {
	// 	background-position: right 35%;
	// }

	// @include media($mobile) {
	// 	background: none;
	// }

	.block-content {
		@include span-columns(6);
		@include media($tablet) {
			@include span-columns(12); 
		}
	}

	.img-content {
		margin-top: 20px;
		@include span-columns(5);
		@include shift(0.5);
		@include media($tablet) { 
			@include shift(0);
			@include span-columns(12);
		}

		img {
			width: 100%;
		}
	}
}

.section-featured {
	background: right 150px url(../images/content/bg-featured.jpg) no-repeat;
	

	@include media($mobile) {
		background: right 150px url(../images/content/bg-featured-m.jpg) no-repeat;
	}

	.block-content-left {
		margin-top: 20px;
		@include span-columns(6);
		@include media($tablet) {
			@include span-columns(12); 
		}
	}
	.block-content-right {
		margin-top: 20px;
		@include span-columns(5.5);
		@include shift(0.5);
		@include media($tablet) {
			@include span-columns(12); 
			@include shift(0);
		}
		a {
			float: left;
			margin-right: 40px;
			@include transition(all 0.2s ease);
			&:hover {
				opacity: 0.8;
			}
		}

		.spotify {
			margin-top: 5%;
		}

		.youtube {
			margin-top: 10%;
		}
	}
}


.section-booking {
	background: center url(../images/content/bg-booking.jpg) no-repeat;
	background-attachment: fixed;
	background-size: 80%; 
	position: relative;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;


	@include media($mobile) {
		background-position: left center;
    	background-attachment: fixed;
	}

		
	.block-rates {
		display: table-cell;
		@include span-columns(6);
		@include media($tablet) {
			@include span-columns(12); 
		}

		p, ul {
			
			margin: 10px 0;
		}
	}
	.block-booking {
		display: table-cell;
		@include span-columns(5);
		@include shift(0.5);
		@include media($tablet) {
			margin-top: 20px;
			@include span-columns(12);
			@include shift(0); 
		}

    	color: $white;
    	background: url(../images/content/red-panel.jpg) no-repeat;
    	background-size: cover;
    	margin-bottom: 50px;
    	.block-inner {
			padding: 10px 30px;
			font-size: 85%;
			@include media($mobile) {
				padding: 0px 20px;
				h3 {
					margin-bottom: 10px;
				}
			}


		}

		p {
			margin: 10px 0;
			line-height: 1.4em;
		}

		a {
			color: $white;
			text-decoration: none;
			border-bottom: 1px solid $offwhite;
			@include transition(all 0.2s ease);
			&:hover {
				border-bottom: 1px solid $pink;
				color: $pink;
			}
    	}
    	
	}
}

.section-disc {
	background: right 70px url(../images/content/wave-disc.jpg) no-repeat;
	background-attachment: fixed;

	@include media($tablet) {
		background-position: left 100px; 
	}
}

.section-qa {
	background: right 70px url(../images/content/wave-qa.jpg) no-repeat;
	background-attachment: fixed;

	@include media($tablet) {
		background-position: left 100px; 
	}
	

	.section-block {
		@include span-columns(8);
		@include media($tablet) {
			@include span-columns(12); 
		}
	}

	.section-quote {

		@include span-columns(3);
		@include shift(1);
		border-bottom: 1px solid $grey;
		display: block;

		@include media($tablet) {
			display: none;
		}

		.quote-block {
			margin-top: 60%;
			border-top: 2px solid $red;
			width: 100%;
			p {
				font-size: 110%;
				float: right;
				text-align: right;
				color: $red;
				font-weight: 600;

			}

			.speechmark-open {
				float: left;
				margin-top: 15px;
				width: 29px;
				height: 22px;
				background: url(../images/content/speechmark-start.png) no-repeat;
			}

			.speechmark-close {
				float: right;
				margin: 0 0 15px 0;
				width: 29px;
				height: 22px;
				background: url(../images/content/speechmark-end.png) no-repeat;
			}

		}
	}

	p {
		margin: 10px 0; 
	}

	.q {
		margin-top: 25px;
		color: $red;
		font-weight: 600;
	}

}

.section-testimonials {
	background: 50% 50% url(../images/content/bg-test.jpg) no-repeat;
	background-size: 70%;
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;

	@include media($tablet){
		background-position: center 150px;
		background-size: 100%; 
		background-attachment: fixed;
	}

	.section-block {
		@include span-columns(8);
		@include media($tablet) {
			@include span-columns(12); 
		}
	}

	p {
		margin-bottom: 10px;
		&:nth-child(even) {
			color: $red;
			font-weight: 600;
			font-size: 90%;	
		}	
	}
}


.section-contact {

	background: right 80px url(../images/content/wave-contact.jpg) no-repeat;
	background-attachment: fixed;

	@include media($tablet) {
		background-position: left 100px; 
	}

	p {
		margin-top: 50px;
	}
}