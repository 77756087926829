.section-art {
 
	.artwork-item {
	    @include media($tablet) {
		     
		    -webkit-animation-name: fade;
		    -webkit-animation-iteration-count: infinite;
		    -webkit-animation-duration: 20s;
		    animation-name: fade;
		    animation-iteration-count: infinite;
		    animation-duration: 20s;
		}
	}

	@-webkit-keyframes fade {
	    0% {opacity: 0;}
	    10% {opacity: 1;}
	    20% {opacity: 0;}
	    30% {opacity: 0;}
	    40% {opacity: 0;}
	    50% {opacity: 0;}
	    60% {opacity: 0;}
	    70% {opacity: 0;}
	    80% {opacity: 0;}
	    90% {opacity: 0;}
	    100% {opacity: 0;}
	}
	@keyframes fade {
		 0% {opacity: 0;}
	    10% {opacity: 1;}
	    20% {opacity: 0;}
	    30% {opacity: 0;}
	    40% {opacity: 0;}
	    50% {opacity: 0;}
	    60% {opacity: 0;}
	    70% {opacity: 0;}
	    80% {opacity: 0;}
	    90% {opacity: 0;}
	    100% {opacity: 0;}
	}

	.artwork-item:nth-child(1) {
		-webkit-animation-delay: 0s;
	}
	.artwork-item:nth-child(2) {
	    -webkit-animation-delay: -2s;
	}
	.artwork-item:nth-child(3) {
	    -webkit-animation-delay: -4s;
	}
	.artwork-item:nth-child(4) {
	    -webkit-animation-delay: -6s;
	}
	.artwork-item:nth-child(5) {
	    -webkit-animation-delay: -8s;
	}
	.artwork-item:nth-child(6) {
	    -webkit-animation-delay: -10s;
	}
	.artwork-item:nth-child(7) {
	    -webkit-animation-delay: -12s;
	}
	.artwork-item:nth-child(8) {
	    -webkit-animation-delay: -14s;
	}
	.artwork-item:nth-child(9) {
	    -webkit-animation-delay: -16s;
	}
	.artwork-item:nth-child(10) {
	    -webkit-animation-delay: -18s;
	}
}