@import "../../bower_components/neat/app/assets/stylesheets/neat";

$visual-grid: false;
$visual-grid-color: grey;
$visual-grid-index: front;
$visual-grid-opacity: 0.1;
 
// Change the grid settings
$max-width: 1266px;

$desktop-large: new-breakpoint(min-width 1440px 12); 
$desktop-small: new-breakpoint(max-width 1101px 12); 
$tablet-large: new-breakpoint(max-width 924px 12);
$tablet: new-breakpoint(max-width 801px 12);
$mobile: new-breakpoint(max-width 480px 12);


// neat v2.0.0 
// $desktop-large: (
//   columns: 12,
//   media: 1440px,
// );

// $desktop-small: (
//   columns: 12,
//   media: 1101px,
// );

// $tablet-large: (
//   columns: 12,
//   media: 924px,
// );

// $tablet: (
//   columns: 12,
//   media: 80px,
// );

// $mobile: (
//   columns: 12,
//   media: 480px,
// );


/// Colours ///
$white: white;
$black: black;

$primary: #a42929;

$red: #990000;

$pink: #ffa7a7;
$grey: #686868;
$offwhite: #f3f3f3;
